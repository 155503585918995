import { httpClient } from "./base";

const REPORT_BASE = `${process.env.REACT_APP_BACKEND_BASE_URL}/report`;

export const ReportApis = {
  fetchCategories: async (token, groupsSelection = []) => {
    return await httpClient.authGet(token, `${REPORT_BASE}/categories${groupsSelection && groupsSelection.length ? `?group_ids=${groupsSelection.join(',')}`: ''}`);
  },

  fetchSubCategories: async (token, category) => {
    let url = REPORT_BASE + `/subCategories?`;
    if (typeof category === "string") category = [category];
    category.forEach((element) => {
      url += `category=${encodeURIComponent(element)}&`;
    });
    return await httpClient.authGet(token, url);
  },

  fetchVendorsAndProducts: async (token, subCategory) => {
    let url = REPORT_BASE + `/vendorlist?`;
    let queryParams = [];
    subCategory.forEach((item) => {
      if (item.category) {
        queryParams.push(`category=${encodeURIComponent(item.category)}`);
        if (item.subcategories && item.subcategories.length > 0) {
          item.subcategories.forEach((subcategory) => {
            queryParams.push(`subcategory=${encodeURIComponent(subcategory)}`);
          });
        }
      }
    });
    return await httpClient.authGet(token, url + queryParams.join("&"));
  },

  fetchProducts: async (token, category, subCategory, vendor) => {
    let url = REPORT_BASE + `/products?`;
    if (typeof category === "string") category = [category];
    category.forEach((element) => {
      url += `category=${encodeURIComponent(element)}&`;
    });
    if (subCategory?.length > 0) {
      if (typeof subCategory === "string") subCategory = [subCategory];
      subCategory.forEach((element) => {
        url += `subcategory=${encodeURIComponent(element)}&`;
      });
    }
    if (vendor?.length > 0) {
      if (typeof vendor === "string") vendor = [vendor];
      vendor.forEach((element) => {
        url += `vendor=${encodeURIComponent(element)}&`;
      });
    }
    return await httpClient.authGet(token, url);
  },

  fetchReportVendors: async (token, requestQuery) => {
    return await httpClient.authPost(
      token,
      `${REPORT_BASE}/vendors-query`,
      requestQuery
    );
  },

  fetchCustomCriteria: async (token, categoryQuery) => {
    return await httpClient.authPost(
      token,
      `${REPORT_BASE}/custom_criteria`,
      categoryQuery
    );
  },
};
